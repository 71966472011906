.headerContainer {
  width: 100%;
  margin:auto;
  height: 4em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

  .logo {
    width: 12em;
    height: 3em;
    background-image: url("../../test1.png");
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor:pointer;
  }

  .menulogo {
    display:flex;
    align-items: center;
  }

  .menu {
    width:1.5em;
    height:1.5em;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.8em;
    margin-right: 1.2em;
    cursor:pointer;
    div {
      width:100%;
      height:2px;
      background-color: #333;
    }
  }

  @media (max-width:991px) {
    .menu {
      display: flex;
    }
  }

  @media (max-width:575px) {
    .logo.collapse {
        width: 3.3em;
        background-size: cover;
        background-position-x: left;
    }
    .Avatar .name {
      display:none;
    }
}


header {
  border: 1px solid $border-color;
  padding: 0 1.2em;
}

.language-switcher {
    display: flex;
    gap: 0.5rem;
  }
  
  .language-switcher button {
    background-color: #eee;
    border: 1px solid #bbb;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: background-color 0.2s;
  }
  
  .language-switcher button:hover {
    background-color: #ddd;
  }
  
  .language-switcher button.selected {
    // background-color: #bbb;
    background-color: #f16093;
    color: white;
  }
  
  .language-switcher img {
    width: 35px;
    height: auto;
  }
.switch {
  display: flex;
  cursor:pointer;

  .label {
      margin-right: .6em;
      color: $grey;
  }
  .label.checked {
      color: $blue-dark;
  }
}

.ant-switch {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-width: 44px;
    height: 22px;
    line-height: 22px;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.25);
    border: 0;
    border-radius: 100px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .ant-switch:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }
  .ant-switch-checked:focus {
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-switch:focus:hover {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .ant-switch-checked {
    background-color: $blue;;
  }
  .ant-switch-loading,
  .ant-switch-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
  .ant-switch-loading *,
  .ant-switch-disabled * {
    -webkit-box-shadow: none;
            box-shadow: none;
    cursor: not-allowed;
  }
  .ant-switch-inner {
    display: block;
    margin: 0 7px 0 25px;
    color: #fff;
    font-size: 12px;
    -webkit-transition: margin 0.2s;
    transition: margin 0.2s;
  }
  .ant-switch-checked .ant-switch-inner {
    margin: 0 25px 0 7px;
  }
  .ant-switch-handle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .ant-switch-handle::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 9px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    content: '';
  }
  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 18px - 2px);
  }
  .ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
    right: -30%;
    left: 0;
  }
  .ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
    right: 0;
    left: -30%;
  }
  .ant-switch-loading-icon {
    position: relative;
    top: 2px;
    color: rgba(0, 0, 0, 0.65);
    vertical-align: top;
  }
  .ant-switch-checked .ant-switch-loading-icon {
    color: #1890ff;
  }
  .ant-switch-small {
    min-width: 28px;
    height: 16px;
    line-height: 16px;
  }
  .ant-switch-small .ant-switch-inner {
    margin: 0 5px 0 18px;
    font-size: 12px;
  }
  .ant-switch-small .ant-switch-handle {
    width: 12px;
    height: 12px;
  }
  .ant-switch-small .ant-switch-loading-icon {
    top: 1.5px;
    font-size: 9px;
  }
  .ant-switch-small.ant-switch-checked .ant-switch-inner {
    margin: 0 18px 0 5px;
  }
  .ant-switch-small.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 12px - 2px);
  }
  .ant-switch-rtl {
    direction: rtl;
  }
  .ant-switch-rtl .ant-switch-inner {
    margin: 0 25px 0 7px;
  }
  .ant-switch-rtl .ant-switch-handle {
    right: 2px;
    left: auto;
  }
  .ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
    right: 0;
    left: -30%;
  }
  .ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
    right: -30%;
    left: 0;
  }
  .ant-switch-rtl.ant-switch-checked .ant-switch-inner {
    margin: 0 7px 0 25px;
  }
  .ant-switch-rtl.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 18px - 2px);
  }
  .ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 12px - 2px);
  }
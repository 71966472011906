.profileContainer {
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;

  .bienvenida {
    text-align: start;
    font-size: 1.8rem;
    margin: 1.6rem 5rem;
  }

  .profile {
    height: 100%;
    flex: 1 1 auto;
    background-color: #fbfbfb;
    overflow-y: auto;
    position: relative;
  }
  .cuerpo {
    width: 65em;
    max-width: 100%;
    //border: 0.2em solid violet;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  // Nombre del Cliente Email y Edad

  .UserData {
    position:relative;
    padding:1.6em 2.4em;
    min-height:22em;
    background-color: #FFF;
    //border:0.2em solid black;
    display: flex;
    flex-direction: column;
    margin-bottom:2em;
    width:25em;
    max-width: 100%;
  }

  .uno {
    //   border:solid 0.2em blue;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  // Avatar
  .datosPersonalesUno {
    margin-left: 2em;
    margin-right: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .datosPersonalesName {
        font-weight:500;
        margin: 0 0 .3rem 0;
        text-align: left;
    }

    div {
        margin: 0 0 .3rem 0;
    }
  }

  //informacion de contacto Telefono y Direccion Del Cliente

  .datosPersonalesDos {
    text-align: left;
    margin-top: 1.7em;
    margin-left: .7em;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .datosPersonalesSection {
        font-weight: 500;
        margin-bottom: .8em;
    }

    div {
        margin-bottom: .6em;
    }
  }
  .tres {
    position:absolute;
    right:1.5em;
    bottom: 1.5em;
    color: #ff518f;
    margin-top: 1em;
  }
  .actualizarDatos {
    cursor: pointer;
    font-weight: 500;
  }
  .hoverUpdate:hover {
    color: #d63e73;
  }
  .ContenedorCita {
    min-height: 22em;
    padding:1.6em 2.4em;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    margin-bottom:2em;
  }
  .FotoCalendario {
    margin:1em .5em;
    width: 18em;
    max-width:100%;
  }
  .PideCita {
    margin-top:1em;
  }

  
  header {
    flex: 0 1 auto;
  }

  @media (max-width: 450px) {
      .uno {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        .datosPersonalesUno {
            margin:0;
            div, h2 {
                width:100%;
                text-align: center;
            }
        }
      }
  }

}


.fa-facebook {
    color: #3b5998;
    font-size: 2em;
    margin-right: 0.2em;
    opacity: 0.8;
}
.fa-facebook:hover {
    opacity: 1;
}

.fa-square-instagram {
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2em;
    opacity: 0.8;
}
.fa-square-instagram:hover {
    opacity: 1;
}

.fa-youtube {
    color: #FF0000;
    font-size: 2em;
    margin-left: 0.2em;
    opacity: 0.8;
}
.fa-youtube:hover {
    opacity: 1;
}

.Footer{
    
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(70, 67, 67);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding:1rem;
    p {
        font-size: 1rem;
    }
    a {
    color: white;
    text-decoration: none;
    font-weight: 700;
    }
    h3 {
        color: #FF518F;
    }
}

.FooterContent {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
}

.allAppointmentsContainer {
    flex: 0 1 auto;
    display:flex;
    flex-direction: row;
    height:100%;
    overflow-y: auto;
    background-color: #fbfbfb;

    .titleContainer {
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 1.6rem 0 1.6rem 5rem;

        .switch {
            margin: .7em 5rem 0 1em;
        }
    }

    .titulo {
        text-align: start;
        font-size: 1.8rem;
    }
    
    .allAppointments {
        position: relative;
        height: 100%;
        flex: 1 1 auto;
        background-color: #fbfbfb;
        overflow-y: auto;
    }

    .appointment {
        background-color: #FFF;
        margin: 0 5% 1.5em 5%;
        padding: 1.7em 1.3em;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 75em;

        .date {
            color: #666;
            font-size: 1.3rem;
            font-weight: bold;
            text-align: right;
            width: 6em;
            flex-shrink: 0;
        }
        .row {
            display:flex;
            justify-content: flex-start;
            align-items: flex-start;
            
            .title {
                font-weight: 500;
                text-align: right;
                width:8.5em;
                margin-right:1.5em;
                flex-shrink: 0;
            }
            .datum {
                text-align: left;
                flex-shrink: 1;
            }
            margin: .5em 0;
        }
        .cancel {
            position:absolute;
            right:0;
            bottom:0;
            color: $pink;
            padding: 0 1.4rem .8em 0;
            font-size: .9rem;
            font-weight: 500;
            cursor: pointer;
            user-select: none;
        }

        @media (max-width:767px) {
            flex-direction: column;
            .date {
                width: auto;
                display: flex;
                flex-direction: row;
                margin-bottom: .3em;
                margin-top: -.5em;
            }
            .date div {
                margin: 0 .5em;
            }
            .row {
                flex-wrap: wrap;
                .title {
                    width: auto;
                }
            }
        }
    }
    
    .buttonContainer {
        margin-bottom:2em;
    }
    
    header {
        flex: 0 1 auto;
    }

}

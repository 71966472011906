.loadingContainer {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index:-1;
    opacity:0;
    transition:opacity .5s;
    display:flex;
}

.visible {
    z-index:99999999;
    opacity:1;
}

.adminContainer {
    flex: 0 1 auto;
    display:flex;
    flex-direction: row;
    height:100%;

    .bienvenida {
        text-align: start;
        font-size: 1.8rem;
        margin: 1.6rem 5rem;
    }
    
    .admin {
        height: 100%;
        flex: 1 1 auto;
        background-color: #fbfbfb;
    }
}
    
header {
    flex: 0 1 auto;
}



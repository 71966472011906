$blue: #02B1E0;
$blue-dark: #009cc7;
$pink: #FF518F;
$pink-dark: #e71c63;
$grey: #666;
$light-grey: #B8B8B8;
$border-color: #E5E5E5;
$error-color: #e75a5a;
$error-border-color: #e49292;
$success-color: #35bb40;
$success-border-color: #92e496;
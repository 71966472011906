.LMContainer {
    height:100%;
    flex: 0 0 auto;
    outline: $border-color solid 1px;
    background-color: white;
    padding: 2.5em 3em;
    box-sizing: border-box;
    z-index:99;
    transition: transform .5s;

    .logo {
        width: 10em;
        height: 3em;
        background-image: url("../../logo3.png");
        background-size:contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: -2em -1em 2em -2em;
        display:none;
      }

    @media (max-width:991px) {
        position: absolute;
        top:0;
        left:0;
        transform: translateX(-100%);
        .logo {
            display: block;
        }
    }

}

.LMContainer.show {
    transform: none;
}

.LMUnder {
    
    z-index:98;
    background-color: rgba(0,0,0,0.4);
    opacity:0;
    transition: opacity .5s;
    pointer-events: none;
    @media (max-width:991px) {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
    }
}

.LMUnder.show {
    opacity:1;
    pointer-events: all;
    cursor:pointer;
}

.LMItem {
    display:flex;
    flex-direction: row;
    align-items: flex-end;
    height:1.3em;
    cursor:pointer;
    margin-bottom: .9em;
}

.LMItem.hidden {
    display:none;
}

.LMItemTitle {
    display:inline-block;
    margin:0 0.8em 0 1em;
}

.LMItem .select {
    display:inline-block;
    width:2px;
    height:90%;
}

.LMItem.selected .select, .LMItem:hover .select {
    background-color: $pink;
}
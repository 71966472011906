
.loginContainer {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    p {
        color: #666;
    }

    h2 {
        font-size: 2em;
        font-weight: normal;
    }

    h3 {
        font-weight:500;
    }

    .login {
        text-align: left;
    }
    
    .login, .register {
        padding: 3em;
        max-width: 24em;
    }
    
    .inputContainer {
        margin-bottom: 1em;
    }
    
    .buttonContainer {
        margin-bottom:2em;
    }
    
    .register p {
        margin-bottom:2em;
    }
    
    .separator {
        width: 2px;
        height: 20em;
        background-color: #E5E5E5;
    }

    @media (max-width: 991px) {
        flex-flow:column;
        .login, .register {
            padding: 0 1em;
            max-width: 24em;
        }
        .separator {
            display:none;
        }
        .register {
            text-align: left;
            max-width: 100%;
            width:26em;
            box-sizing: border-box;
        }
        .register p {
            display:none;
        }
    }
}

.App {
    height: 100%;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
}

header, footer {
    flex: 0 1 auto;
}

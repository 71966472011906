@import 'scss/variables';
@import 'components/Avatar/Avatar';
@import 'components/CTAButton/CTAButton';
@import 'components/Header/Header';
@import 'components/LeftMenu/LeftMenu';
@import 'components/Message/Message';
@import 'components/Switch/Switch';
@import 'components/DateTimePicker/DateTimePicker';
@import 'containers/Appointments/Appointments';
@import 'containers/AllAppointments/AllAppointments';
@import 'containers/New-appointment/New-appointment';

.App {
    text-align: center;
  
}
  
html,
body,
#root {
    height: 100%;
    margin: 0;
}

.container{
    flex: 0 1 auto;
    display:flex;
    flex-direction: row;
    height:100%;
    overflow-y: auto;
    background-color: #fbfbfb;
    
    .title {
        text-align: start;
        font-size: 1.8rem;
        margin: 1.6rem 5rem;
    }

    .right {
        position: relative;
        height: 100%;
        flex: 1 1 auto;
        background-color: #fbfbfb;
        overflow-y: auto;
    }

    .form {
        background-color: #FFF;
        margin: 0 5% 1.5em 5%;
        padding: 1.7em 1.3em;
        position: relative;
        max-width: 75em;
    }

    .ant-steps {
        padding: 0 1rem;
    }

    .steps-content {
        min-height: 17em;
        padding-top: 1em;
        text-align: center;
        position:relative;

        .option {
            width:17em;
            padding: .8em;
            border: 1px solid #e9e9e9;
            border-radius: 2px;
            margin:1em auto;
            cursor:pointer;
            max-width: 100%;
            box-sizing: border-box;
        }
        .option:hover {
            background-color: $pink;
            color:white;
        }
        .back {
            color: $pink;
            font-weight: 500;
            cursor:pointer;
            user-select: none;
            margin-left:1em;
        }
        .dentistDetails {
            margin: .5em 0 1.5em 0;
            div {
                display: inline-block;
                margin: 0 1em;
                @media (max-width:499px) {
                    margin: 0;
                }
            }
        }
        .confirm {
            text-align: left;
            width:95%;
            margin: 1em auto;

            .appointmentDetails {
                display:flex;
                justify-content: space-between;
                align-items: center;
            }

            .appointmentTitle {
                font-weight: 500;
                margin-bottom:.5em
            }

            .separator {
                width:100%;
                height:.15em;
                background-color: #E5E5E5;
                margin: 1.5em 0;
            }

            textarea {
                resize:none;
                font-family:inherit;
                padding: .8em 1.2em;
                margin-top: .3em;
            }

            .buttonContainer {
                margin-top:1em;
                display:flex;
                justify-content: flex-end;
            }
        }
    }
}
